import React from "react";
import "./Tag.css";

const Tag = ({ startDate, endDate, title, subTitle, subsubTitle, where }) => {
  return (
    <div className="Tag__container">
      <div className="Tag__dateContainer">
        <h2> {startDate} </h2>
        <h2> {endDate} </h2>
      </div>
      <div className="Tag__textContainer">
        <h3>{title}</h3>
        {where ? <small className="Tag__where"> {where} </small> : <></>}
        <p className="Tag__subTitle">{subTitle}</p>
        <p className="Tag__subsubTitle">{subsubTitle}</p>
      </div>
    </div>
  );
};

export default Tag;
