import React, { useEffect } from "react";
import { computeFingerprint } from "simple-fingerprint";
import firebase from "../firebase";
const store = firebase.store;

const SendMessage = () => {
  useEffect(() => {
    async function sendData() {
      let fingerprint = await computeFingerprint();

      store
        .collection("logs")
        .get()
        .then((querySnapshot) => {
          let finger = false;
          querySnapshot.forEach((doc) => {
            if (doc.data().fingerprint === fingerprint) finger = true;
          });
          if (!finger)
            store
              .collection("logs")
              .doc()
              .set({ fingerprint, date: new Date() })
              .then(() => {
                console.log("Success");
              })
              .catch((error) => {
                console.log("Error: Storing");
              });
        })
        .catch((error) => {
          console.log("Error: Reading");
        });
    }
    sendData();
  }, []);

  return <div></div>;
};

export default SendMessage;
