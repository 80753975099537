import React from "react";
import "./Header.css";
import logo from "../media/img/logo.png";
import logoWhite from "../media/img/logo_white.png";
import { Link } from "react-router-dom";

const Header = ({ invert }) => {
  let headerStyle = "";
  let headerImg = "";

  if (invert === true) {
    headerStyle = "invert";
    headerImg = <img className="header__logo" src={logoWhite} alt="logo" />;
  } else {
    headerStyle = "normal";
    headerImg = <img className="header__logo" src={logo} alt="logo" />;
  }

  return (
    <div className={"header " + headerStyle}>
      <Link to="/" replace>
        {headerImg}
      </Link>
      <div className="header__links">
        <a href="https://idash3.github.io">
          {/* <Link to="/blog" replace> */}
          <span>Blog</span>
          {/* </Link> */}
        </a>
        {/* <Link to="/cv" replace>
          <span>Me</span>
        </Link> */}
      </div>
    </div>
  );
};

export default Header;
