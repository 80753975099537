import React from "react";
import "./PageCard.css";
import colors from "../app/colors";

const PageCard = ({ backgroundColor = colors.backgroundPrimary, children }) => {
  return (
    <div className="PageCard__container" style={{ backgroundColor }}>
      {children}
    </div>
  );
};

export default PageCard;
