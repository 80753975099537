import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyDEW5vVST3jA1ZZPcGgXCsd18Nb5ifsYos",
  authDomain: "cvcv-a3f11.firebaseapp.com",
  databaseURL: "https://cvcv-a3f11.firebaseio.com",
  projectId: "cvcv-a3f11",
  storageBucket: "cvcv-a3f11.appspot.com",
  messagingSenderId: "461291894683",
  appId: "1:461291894683:web:579d57bc847889f1e1d33e",
};

const app = firebase.initializeApp(firebaseConfig);
const store = firebase.firestore();

export default {
  store,
};
