import React, { useState } from "react";

const Block = ({
  backgroundColor = "#EE7B6C",
  children,
  color = "white",
  className,
  title,
  href,
  reverseChildren,
  reversible = false,
  download = "",
}) => {
  const [reverse, setReverse] = useState(false);

  const handleClick = () => {
    setReverse(!reverse);
  };

  return (
    <a
      className={`grid-item post-preview ${className}`}
      target="_blank"
      style={{ backgroundColor: backgroundColor, color: color }}
      href={href}
      onClick={handleClick}
      download={download}
    >
      <h1 className="grid-item-header">{title}</h1>
      {reversible ? (!reverse ? children : reverseChildren) : children}
    </a>
  );
};

export default Block;
