import React from "react";
import "./SkillsPageCard.css";

import PageCard from "./PageCard";
import Skill from "./Skill";

const SkillsPageCard = () => {
  return (
    <PageCard>
      <div className="SkillsPageCard__header" id="skills">
        {/* <h1>Skills</h1> */}
        <span>
          I have spent a significant portion of my early youth to programming,
          you may look at my {}
          <a href="https://idash3.github.io" className="SkillsPageCard__anchor">
            blogs
          </a>
          {} to get an idea of my latest projects.
        </span>
      </div>
      <article className="SkillsPageCard__container">
        <div>
          <h2 className="SkillsPageCard__heading">Code</h2>
          <Skill
            title="JS"
            textComponents={
              <>
                <p>
                  <b>Full Stack Developer</b>
                </p>
                <small>
                  My personal Stack of preference is: React + Express + MongoDB
                </small>
                <p>
                  Developed an entire, functional, working app from stratch, by
                  my own. Check out my blog entry: {}
                  <a
                    className="SkillsPageCard__anchor"
                    href="https://idash3.github.io/2021/07/20/apps-are-easy.html"
                  >
                    Apps Are Easy.
                  </a>
                  {} I also made this simple personal app.
                </p>
              </>
            }
            // colorOne="#f3f9a7"
            // colorTwo="#cac531"
            colorOne="rgb(255, 238, 88)"
            colorTwo="rgb(255, 241, 118)"
          />
          <Skill
            title="Python"
            textComponents={
              <>
                <p>
                  General Python use, been coding in python since age 12, from
                  web-scraping to backend development with Django.
                  <div>
                    <span>Some projects made with Python: {}</span>
                    <a
                      className="SkillsPageCard__anchor"
                      href="https://github.com/iDash3/LoFi-Girl-AI"
                    >
                      LoFi Girl AI.{" "}
                    </a>
                    I also made the backend for my {}
                    <a
                      className="SkillsPageCard__anchor"
                      href="https://idash3.github.io/2021/07/20/apps-are-easy.html"
                    >
                      International App
                    </a>
                    {} using Django.
                  </div>
                </p>
              </>
            }
            // colorOne="#11998e"
            // colorOne="#38ef7d"
            colorOne="rgb(200, 230, 201)"
            colorTwo="rgb(129, 199, 132)"
          />
          <Skill
            title="C++"
            textComponents={
              <>
                <p>
                  Been learning C/C++ for almost 3 years now, check a simple
                  example at: {}
                  <a
                    className="SkillsPageCard__anchor"
                    href="https://github.com/iDash3/Simple-Convolution-Kernel-Example"
                  >
                    Convolution Example
                  </a>
                </p>
              </>
            }
            // colorOne="#4e54c8"
            // colorTwo="rgb(253, 240, 236)"
            colorOne="rgb(253, 240, 236)"
            colorTwo="rgb(253, 240, 236)"
          />
          <Skill
            title="Others"
            textComponents={
              <>
                <p>
                  I have experience working with other languages like Java (I've
                  developed Android apps before), C# (mostly Unity work, which
                  is quite fun), Matlab (used in physics homeworks) and others.
                  However this experience is clearly outweighted by my other
                  projects to which I dedicate a lot of time.
                </p>
                <p>
                  I am also comfortable working with Linux (I personally use
                  Manjaro + gnome)
                </p>
              </>
            }
            // colorOne="#f3f9a7"
            // colorTwo="#cac531"
            colorOne="rgb(253, 240, 236)"
            colorTwo="rgb(253, 240, 236)"
          />
        </div>
        <div>
          <h2 className="SkillsPageCard__heading">Science</h2>
          <Skill
            title="Physics"
            textComponents={
              <>
                <p>
                  Currently undergoing the 9th semester of the physics
                  curriculum.
                </p>
                <p>
                  Studied subjects such as: Mathematical Physics, Linear
                  Algebra, Differential Equations, Quantum Physics,
                  Electrodynamics
                </p>
              </>
            }
            // colorOne="#74ebd5"
            // colorTwo="#ACB6E5"
            colorOne="lightblue"
            colorTwo="lightblue"
          />
          <h2 className="SkillsPageCard__heading">Design</h2>
          <Skill
            title="3D"
            textComponents={
              <>
                <p>
                  I've spent a significant amount of time working with 3D
                  modelling software, some examples can be read on my blog.
                </p>
                <h5>Fusion 360</h5>
              </>
            }
            colorOne="rgb(224, 247, 250)"
            colorTwo="rgb(224, 247, 250)"
          />
          <Skill
            title="Tools"
            textComponents={
              <>
                <p>
                  I've worked with non-profits to make illustrations, I
                  developed the UI design for all of my apps / projets and I
                  really enjoy working with video.
                </p>
                <h5>Photoshop</h5>
                <h5>Adobe XD</h5>
                <h5>Premier Pro</h5>
              </>
            }
            // colorOne="#B79891"
            // colorTwo="#94716B"
            colorOne="rgb(224, 247, 250)"
            colorTwo="rgb(224, 247, 250)"
          />
        </div>
      </article>
    </PageCard>
  );
};

export default SkillsPageCard;
