import React from "react";
import "./Blogs.css";

import Blog from "./Blog";
import { Link } from "react-router-dom";

const Blogs = () => {
  const blogsDb = [
    {
      title: "New host for these blogs: Learning Next.js",
    },
    {
      title: "I must work on better titles",
    },
    {
      title: "Keybrd.xyz, sight reading piano trainer",
    },
    {
      title: "C++ and OpenGL for a non euclidean geometry engine",
    },
    {
      title: "Bucc: College through books. (Beta running!)",
    },
    {
      title:
        "Xbox Kinect as a muscle volume progress watchman (temporal codename: SpotNect)",
    },
    {
      title: "Decentralized library",
    },
    {
      title: "Web Design: DNA mods",
    },
    {
      title: "Installling 3D printed antennas on the top of a mountain ?",
    },
    {
      title: "Design: My logo looks like a poor man's Beats logo",
    },
  ];
  return (
    <div className="blogs">
      <p className="blogs__heading">Blog Entries:</p>
      <div className="blogs__container">
        <span>Slowly bringing the old posts after upgrading the site</span>
        {blogsDb &&
          blogsDb.map((blog) => (
            <Link to="/blog/1">
              <Blog title={blog.title}></Blog>
            </Link>
          ))}
      </div>
    </div>
  );
};

export default Blogs;
