import React from "react";

const IconText = ({ title, icon, href }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", margin: "3px 0" }}>
      <i className="material-icons" style={{ marginRight: "5px" }}>
        {icon}
      </i>
      {href ? <a href={href}>{title}</a> : <span>{title}</span>}
    </div>
  );
};

export default IconText;
