import React from "react";
import "./Blog.css";

const Blog = ({ title }) => {
  return (
    <div className="blog">
      <div className="blog__text">
        <h4>{title}</h4>
      </div>
    </div>
  );
};

export default Blog;
