import React from "react";

import GrowBar from "./GrowBar";

const Languages = () => {
  return (
    <div>
      <GrowBar
        percent={100}
        title="Spanish"
        subTitle="Mother Language"
      ></GrowBar>
      <GrowBar percent={88} title="English" subTitle="C1 TOEFL"></GrowBar>
      <GrowBar
        percent={65}
        title="German"
        subTitle="B2 certified, A2 Goethe"
      ></GrowBar>
      <GrowBar
        percent={35}
        title="French"
        subTitle="A2, 3 years course"
      ></GrowBar>
    </div>
  );
};

export default Languages;
