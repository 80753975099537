import React from "react";
import "./GrowBar.css";

const GrowBar = ({ percent, subTitle, title }) => {
  const linesDark = "/".repeat(20 * (percent / 100));
  const linesLight = "/".repeat(20 * (1 - percent / 100));

  return (
    <div className="Growbar">
      <span className="GrowBar__title">{title}</span>
      <span className="GrowBar__lines">{linesDark}</span>
      <span className="GrowBar__restLines">{linesLight} </span>
      {subTitle && <span className="GrowBar__subTitle">{subTitle}</span>}
    </div>
  );
};

export default GrowBar;
