import React from "react";
import "./InforCard.css";

import IconText from "./IconText";
import Instructions from "./Instructions";
import Contact from "./Contact";

const InforCard = () => {
  return (
    <article className="MainPageCard__container">
      <div>
        <h1>Isaac Bautista Silva</h1>
        <h4>
          I am a physics student with a deep devotion to learning, from
          computers to linguistics I find peace in understanding the world.
        </h4>
        {/* <p>
          <span style={{ marginLeft: "120px" }}>
            🚨 Currently updating this site 🚨
          </span>
        </p> */}
        <IconText icon="place" title="Monterrey, Mexico"></IconText>
        <IconText icon="cake" title="22 years old"></IconText>

        <div className="MainPageCard__buttons">
          <a className="MainPageCard__button" href="#academics">
            Academics
          </a>
          <a className="MainPageCard__button" href="#projects">
            Projects
          </a>
          <a className="MainPageCard__button" href="#skills">
            Skills
          </a>
          <a className="MainPageCard__button" href="#blog">
            Blog
          </a>
        </div>
        {/* <Instructions text="Click on top of the elements to get documents!" /> */}
        {/* <Contact /> */}
      </div>

      <div className="MainPageCard__image">
        <img
          src={require("../media/img/MEbutSMILING.jpg")}
          alt="profile"
          style={{ objectFit: "cover" }}
        />
      </div>
    </article>
  );
};

export default InforCard;
