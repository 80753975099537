import React from "react";
import "./Skill.css";

const Skill = ({
  title,
  colorOne = "#24FE41",
  colorTwo = "#FDFC47",
  black = true,
  textComponents,
}) => {
  const textStyle = black ? { color: "black" } : { color: "white" };
  textStyle["top"] = `${title.length * 5 + 6}px`;
  textStyle["right"] = `${-((title.length * 12) / 2) + 10}px`;

  return (
    <div
      className="Skill"
      style={{
        backgroundColor: "lightred",
        background: `linear-gradient(135deg,${colorOne},${colorTwo})`,
        minHeight: `${title.length * 15 + 9}px`,
      }}
    >
      <div className="Skill__titleVertical" style={textStyle}>
        {title}
      </div>
      <div className="Skill__text">{textComponents}</div>
    </div>
  );
};

export default Skill;
