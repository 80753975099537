import React from "react";
import Block from "../components/Block";

import Cards from "../components/Blocks";
import Header from "../components/Header";
import InforCard from "../components/InforCard";
import Instructions from "../components/Instructions";
import Languages from "../components/Languages";
import Poster from "../components/Poster";
import SkillsPageCard from "../components/SkillsPageCard";
import Tag from "../components/Tag";
import AppList from "../components/AppList";
import updatingGif from "../lottie/updating.gif";

const MainScreen = () => {
  const BlockText = ({ text }) => {
    return <p className="grid-item-text">{text}</p>;
  };

  return (
    <>
      <Header></Header>
      {/* <div className="app__container">
        <p>Updating site...</p>
        <img src={updatingGif} alt="Updating site..." />
        <Poster imageUrl={require("../media/img/void.png")} />
      </div> */}

      {/* Name, location and so on */}
      <InforCard />

      {/* Instructions */}
      {/* <Instructions
        style={{ marginTop: "150px", marginBottom: "0" }}
        title="Tip"
        text="Click on the cards, some contain links or more information!"
      /> */}

      {/* Academic Section */}
      <Cards title="Academics." id="academics" className="grid-items-peak">
        <Block
          title="Education"
          backgroundColor="lightblue"
          color="black"
          // className="grid-item-fifth"
        >
          <Tag
            startDate="2018"
            endDate="2023"
            title="BS Engineering Physics"
            subTitle="Tecnológico de Monterrey Campus Monterrey"
            subsubTitle="Monterrey, Mexico"
          ></Tag>
          <Tag
            startDate="2021"
            endDate="2022"
            title="BS Physics (Exchange Studies)"
            subTitle="TU Dresden"
            subsubTitle="Dresden, Germany"
          ></Tag>
          <Tag
            startDate="2021"
            endDate="2022"
            title="DAAD-Scholarship KOSPIE"
            subTitle="Deutscher Akademischer Austauschdienst (DAAD)"
            subsubTitle="Germany"
          ></Tag>
          <Tag
            startDate="2015"
            endDate="2018"
            title="Highschool"
            subTitle="TecMilenio"
            subsubTitle="Mexico City, Mexico"
          ></Tag>
        </Block>
        <Block
          title="Languages"
          backgroundColor="#FDF0EC"
          color="black"
          className="grid-item-third"
        >
          <Languages />
        </Block>
      </Cards>

      {/* Projects section */}
      <Cards title="Projects." id="projects">
        <Block
          className="grid-items-feature-first"
          title="International Student App"
          href="https://play.google.com/store/apps/details?id=sunn.app"
          // reversible={true}
          reverseChildren={
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <div className="square">
                <a
                  href="https://play.google.com/store/apps/details?id=sunn.app"
                  target="_blank"
                >
                  <div className="square__one">
                    Click to see the published app
                  </div>
                </a>
                <a
                  href="https://idash3.github.io/2021/07/20/apps-are-easy.html"
                  target="_blank"
                >
                  <div className="square__two">Click to see the blog post</div>
                </a>
              </div>
            </div>
          }
        >
          {/* SUNN */}
          <BlockText
            text="Connect with students abroad, get to know friends from countries all around the world, plan
trips and events together!"
          />
          <div className="blog-banner-content">
            <img
              src={require("../media/img/phones.png")}
              alt="phones"
              style={{ width: "40vw" }}
            />
          </div>
          <BlockText
            text="Envisioned the idea, meticulously designed the UI / UX, coded both the fronted (React Native) and backend
(Django + SQL), arranged 3 rounds of user trials and launched the app to the stores"
          />
          <small>Click this card to see the published app!</small>
        </Block>

        {/* SECOND */}
        <Block
          title="SPECTER: Legal Assistant"
          backgroundColor="#FDF0EC"
          color="black"
        >
          <BlockText text="Improve the mexican legal procedures: make them faster, easier to comply and available to all habitants no matter their income." />
          <div className="blog-banner-content">
            <img
              src={require("../media/img/specter.jpg")}
              alt="specter"
              style={{ width: "20vw" }}
            />
            <p>
              <small>
                Photo from a presentation we gave to the Mexican bar
              </small>
            </p>
          </div>
          <BlockText text="Legal proceedings in Mexico are quite lengthy, confusing and expensive. Some 'simple' processes such as divorces may take a long time and significant money." />
          <BlockText text="With our legal assitant we aim to both provide easier analysis tools to the courts, so they are more efficient; and provide the ability for everyone to make a legal suit." />
        </Block>

        {/* ZEPHEON DESIGN */}
        <Block
          title="Zepheon Design"
          backgroundColor="#212121"
          href="https://www.zepheon.com"
        >
          <div className="blog-banner-content">
            <img
              src={require("../media/img/void.png")}
              alt="logo"
              style={{ width: "200px" }}
            />
          </div>
          <span>
            Formed a design collective, been working with artist to bring it to
            life
          </span>
        </Block>
      </Cards>

      {/* Skills Section */}
      <Cards title="Skills." id="skills">
        {/*  */}
        <Block
          title="Work Experience"
          backgroundColor="lightgreen"
          href={require("../media/docs/orbit.pdf")}
          download="orbit.pdf"
          color="black"
        >
          <Tag
            startDate="04.2022"
            endDate="09.2022"
            title="Porsche Engineering"
            where={"Stuttgart, Germany"}
            subTitle="Machine Learning and Data Science Intern"
            subsubTitle="Worked on building the Machine Learning Infrastructure for the Porsche baseline"
          ></Tag>
          <AppList>
            <p>Main activities consisted of:</p>
            <li>
              Filtering and analysing really big amounts of data using{" "}
              <b>python</b>, <b>pandas</b> and <b>shell scripting</b>
            </li>
            <li>
              Understanding and improving running ML models, using{" "}
              <b>Pytorch</b> and <b>Tensorflow</b>
            </li>
            <li>Building the core foundation for the entire codebase</li>
          </AppList>

          <Tag
            startDate="2017"
            endDate="2018"
            title="Orbit Cloud"
            subTitle="IT Employee"
            subsubTitle="Worked on setting up and maintaining virtual private servers"
            where="Mexico State, Mexico"
          ></Tag>
          <p>
            Created a business plan for small and medium size enterprises which
            increased the clients by 30%, then directed a small development team
            to take the idea further
          </p>
          <small>Click this card to see my drafted business plan!</small>
        </Block>

        <Block title="Relevant School Courses" backgroundColor="#3bab87">
          <AppList>
            <li>
              Mathematical Physics
              <li>Linear Algebra</li>
              <li>Differential Equations </li>
              <li>Solid State Physics</li>
              <li>Numerical Methods </li>
              <li>Alternative Energies </li>
              <li>Quantum Physics </li>
              <li>High Performance Programming (Germany) </li>
              <li>Machine Learning (Germany)</li>
            </li>
          </AppList>
        </Block>
      </Cards>

      <SkillsPageCard />

      {/* Blog section */}
      <Cards title="Blog." id="blog">
        <Block
          className="grid-items-feature-first"
          title="Apps are easy*"
          href="https://idash3.github.io/2021/07/20/apps-are-easy.html"
          backgroundColor="#F79F63"
        >
          <div className="blog-banner-content">
            <img
              src={require("../media/img/wtf.png")}
              alt="phone"
              style={{ width: "100px" }}
            />
          </div>
          <BlockText text="How I started my app and why they are not as hard as people think" />
        </Block>
        <Block
          className="grid-item-mini"
          title="College through books"
          backgroundColor="#f1f0fe"
          color="black"
          href="https://idash3.github.io/2021/01/28/bbuucc-college-through-books.html"
        />
        <Block
          className="grid-item-mini"
          title="LoFi Girl but it's an AI"
          backgroundColor="#224a54"
          href="https://idash3.github.io/2021/07/13/lofi-girl-but-it's-an-AI.html"
        />
      </Cards>
    </>
  );
};

export default MainScreen;
