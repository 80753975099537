import React from "react";
import Block from "./Block";

const Cards = ({ title, className, children, id }) => {
  return (
    <div className={`idk__container ${className}`} id={id}>
      <h2 className="idk">{title}</h2>
      {children}
    </div>
  );
};

export default Cards;
