import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Header from "./components/Header";
import Blogs from "./components/Blogs";
import Blog from "./components/Blog";
// import About from "./components/About";
import MainScreen from "./screens/MainScreen";
import SendMessage from "./components/SendMessage";

function App() {
  return (
    <Router>
      <Switch>
        {/* Individual blog section */}
        <Route path="/b/:id:">
          <Header invert={true}></Header>
          <Blog></Blog>
        </Route>
        {/* Blogs section */}
        <Route path="/blog">
          <Header invert={true}></Header>
          <Blogs></Blogs>
        </Route>
        {/* About section */}
        <Route path="/cv">
          <SendMessage />
          <Redirect to="/"></Redirect>
          {/* <Header></Header>
          <About></About> */}
        </Route>
        {/* Main section */}
        <Route path="/">
          <MainScreen />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
